import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import SurveyParticipantAttendees from './surveyParticipantAttendees/validateLink';
import App from './App';
import { Provider } from "react-redux";
import reducers from "./reducers";
// import { usePromiseTracker } from 'react-promise-tracker';
// import Loader from 'react-promise-loader';
// import { getPrimaryColor } from "./utility";

import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composer(applyMiddleware(thunk)));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
{/* <Loader zIndex={1010} color={getPrimaryColor()} background={'rgba(0,0,0,.5)'} loading={false} promiseTracker={usePromiseTracker} /> */}
  {/* <SurveyParticipantAttendees/> */}
  </Provider>
  </React.StrictMode>
);
reportWebVitals();
