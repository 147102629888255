import './App.css';
import { BrowserRouter as Router, Route, Switch,HashRouter } from 'react-router-dom';
import SurveyParticipantAttendees from './surveyParticipantAttendees/validateLink';

function App() {
  return (
    <Router>
      <Switch>
      <Route path="/" component={SurveyParticipantAttendees} />
        {/* <SurveyParticipantAttendees/> */}
    </Switch>
	</Router>
  );
}

export default App;
