import React, { Component } from "react";
import { toast } from "react-toastify";
import { verifyLink } from "./service";
import { BrowserRouter as Router, Route, Switch,HashRouter, Redirect } from 'react-router-dom';
import ParticipantAttendees from "./participantAttendees";
import InvalidUrl from "./invalidUrl";
import SurveyExpired from "./surveyExpired";
import SurveySubmitted from "./surveySubmitted";
import ServiceUnavailable from "./serviceUnavailable";

export default class SurveyParticipantAttendees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            participantAttendees: {
                surveyUrlKey: "",
                participantUrlKey: "",
            },
        };
    }

    componentDidMount() {
        const link = window.location.href;
        const urlParts = link.split('/');
        const uuid1 = urlParts[6];
        const uuid2 = urlParts[7];
        this.setState(
            {
                participantAttendees: {
                    surveyUrlKey: uuid1,
                    participantUrlKey: uuid2
                }
            },
            () => {
                this.verifyLink(this.state.participantAttendees);
            }
        );
    }


    verifyLink = (data) => {
        verifyLink(data)
            .then(res => {
                if (res.status === "OK") {
                    toast.success(res.message);
                    this.setState({ participantAttendees: res.data});
                }
                else if (res.status == "ALREADY_REPORTED") {
                    let message = res.data;
                    this.props.history.push('/survey/submitted', { message: message });
                }
                else if (res.status == "NOT_FOUND") {
                    this.props.history.push('/survey/not-found');
                }
                else if (res.status == "BAD_REQUEST") {
                    this.props.history.push('/survey/expired');
                }
                else if (res.status == "SERVICE_UNAVAILABLE") {
                    this.props.history.push('/survey/service-unavailable');
                }
            })
    }

    render() {
        return (
                <Switch>
                    <Route exact path={`/survey/not-found`} component={InvalidUrl} />
                    <Route exact path={`/survey/service-unavailable`} component={ServiceUnavailable} />
                    <Route exact path={`/survey/expired`} component={SurveyExpired} />
                    <Route exact path={`/survey/submitted`} component={SurveySubmitted} />
                    <Route exact path={''} component={(props) => <ParticipantAttendees {...props} participantAttendees={this.state.participantAttendees} />} />
                </Switch>
        );
    }
}
