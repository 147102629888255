import React, { useEffect, useState } from 'react';

 

const CountdownTimer = ({ targetDate }) => {
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

 

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate.getTime() - now;

 

      if (distance < 0) {
        // Countdown reached the target date, clear the interval
        clearInterval(interval);
        window.location.reload();

      } else {
        // Calculate remaining days, hours, and minutes
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

 

        // Update the countdown state
        setCountdown({ days, hours, minutes, seconds });
      }
    }, 1000);

 

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [targetDate]);

 

  return (
    <div className='timer-countdown'>
      <div className='timer-text'>
        <h5 className='second-timer-text'>Remaining Time</h5>
      </div>
      <div className="count days">
        <p>{countdown.days} </p>
        <p className='timer-metric-text'>Day</p>
      </div>
      <div className="count hours">
        <p>{countdown.hours}</p>
        <p className='timer-metric-text'>Hours</p>
      </div>
      <div className="count minutes">
        <p> {countdown.minutes}</p>
        <p className='timer-metric-text'>Minutes</p>
      </div>
      <div className="count seconds">
        <p> {countdown.seconds}</p>
        <p className='timer-metric-text'>Seconds</p>
      </div>
    </div>
  );
};

 

export default CountdownTimer;