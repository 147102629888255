import { Component } from "react";

class ServiceUnavailable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<>
            <div className="content container-fluid">
                <div className="row">
                    <div className="survey-backround2 survey-expaired-wrapper">
                        <p className="session-exp-text">
                            Please note that this survey link has been temporarily suspended. Please revisit the link later or contact your survey administrator for more information.</p>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default ServiceUnavailable;