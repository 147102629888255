import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import { toast } from "react-toastify";



const surveyHttp = axios.create({
    baseURL: "https://hrmsservice.workplus-hrms.com/survey-service",
   
});

let config = {
    headers: {
        'Content-Type': 'application/json'
    }
};
export const getWithAuthSurvey = (path) => {
    return trackPromise(surveyHttp.get(path).then(res => {
            return Promise.resolve(res);
    }).catch(err => {
        toast.error(err.message);
    }));
}

export const deleteWithAuthSurvey = (path, body) => {
    return trackPromise(surveyHttp.delete(path, { data: body }).then(res => {
    }));
}


export const postWithAuthSurvey = (path, body) => {
    let data = body;
    return trackPromise(surveyHttp.post(path, data).then(res => {
            return Promise.resolve(res);
        }
    ))
}
export const putWithAuthSurvey = (path, body) => {
    let data = body;
    return trackPromise(surveyHttp.put(path, data).then(res => {
            return Promise.resolve(res);
        }
    ))
}

