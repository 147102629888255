import { getWithAuthSurvey } from "../HttpRequest";
import { setSurveyLanguageData } from "./DropdownReducer";
import { toast } from 'react-toastify';


export const DropdownService = {

    getSurveyLanguages: function (surveyId) {
        return dispatch => {
            if (surveyId > 0) {

                return getWithAuthSurvey("survey-participant-attendees/select?surveyId=" + surveyId).then(response => {
                    if (response.data && response.data.status === "OK") {

                        dispatch(setSurveyLanguageData(response.data.data));
                    } else {
                        toast.error(response.data.message);
                    }
                }).catch(err => {
                    toast.error(err.message) //"Something went wrong");
                });;
            }
        }
    },

};
