const initialState = {
    surveyLanguages: [],
}
const DropdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SURVEY_LANGUAGE_DATA':
            return {
                ...state,
                surveyLanguages: action.surveyLanguages
            };
        default:
            return state;
    }
};
export default DropdownReducer;

export function setSurveyLanguageData(surveyLanguages) {
    return {
        type: 'SET_SURVEY_LANGUAGE_DATA',
        surveyLanguages: surveyLanguages
    }
}
