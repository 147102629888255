import React, { Component } from 'react';
import { saveParticipantQuestion } from '../surveyParticipantAttendees/service';
import { toast } from 'react-toastify';

class SingleAnswerType extends Component {
  handleAnswerChange = (e) => {
   let id= e.target.value;
    const { participantAttendees } = this.props;
    const { surveyQuestionResponse } = this.props;
    const { surveyResponseAnswers } = this.props;
    const answer = e.target.nextSibling.textContent;
    const answerIds = id.toString();

    const participantQuestion = {
      answerIds: answerIds,
      answers: answer,
      id: surveyResponseAnswers.id || 0,
      participantId: participantAttendees.participantId,
      question: surveyResponseAnswers.question || surveyQuestionResponse.question,
      questionId: surveyResponseAnswers.questionId || surveyQuestionResponse.id,
      surveyId: surveyQuestionResponse.surveyId || '',
      languageId: participantAttendees.languageId,
    };

    this.save(participantQuestion);
  };

  save = (participantQuestion) => {
    saveParticipantQuestion(participantQuestion)
      .then((res) => {
        if (res.status === "OK") {
          this.props.fetchList(this.props.participantAttendees.languageId);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log({ err });
        toast.error("Error while saving answer");
      });
  };

  render() {
    const { answers, surveyResponseAnswers } = this.props;
    return (
      <div className='answer-type-options mt-2'>
        {answers.map((answer) => (
          <label className='survey-label2 h5' key={answer.id}>
            <input
              type="radio"
              name={`question-${surveyResponseAnswers.questionId}`}
              value={answer.id}
              defaultChecked={parseInt(surveyResponseAnswers.answerIds) === answer.id}
              onChange={this.handleAnswerChange}
            />
            {!answer.image && <label className='survey-label2 h4'>{answer.answer}</label>}
            {answer.image && <img className='square-image' style={{marginLeft: "25px", borderRadius: '5%'}} src={answer.image} alt={answer.answer} />}
          </label>
        ))}
      </div>
    );
  }
}

export default SingleAnswerType;
