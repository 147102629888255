import { postWithAuthSurvey, getWithAuthSurvey, putWithAuthSurvey } from "../HttpRequest";

const servicePath = "/survey-participant-attendees";

export function verifyLink(participantAttendees) {
    let post = postWithAuthSurvey(servicePath, participantAttendees);
    return post.then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        console.log({ err })
        return Promise.reject(err);
    });
}

export function getDataByLanguage(languageId, surveyId) {
    let post = getWithAuthSurvey(servicePath + "/description?languageId=" + languageId + "&surveyId=" + surveyId);
    return post.then(res => {
        return Promise.resolve(res.data);
    }).catch(err => {
        console.log({ err })
        return Promise.reject(err);
    });
}
export function getAllQuestionAndAnswer(surveyId, languageId, participantId) {
    let path = `${servicePath}?languageId=` + languageId + `&participantId=` + participantId + `&surveyId=` + surveyId;
    return getWithAuthSurvey(path).then(res => {
        return Promise.resolve(res.data);

    }).catch(err => {
        return Promise.reject(err);
    });
}

export function saveParticipantQuestion(surveyQuestion) {
    let post;

    if (surveyQuestion.id === 0) {
        post = postWithAuthSurvey(`${servicePath}/save`, surveyQuestion, false);
    } else {
        post = putWithAuthSurvey(`${servicePath}/update`, surveyQuestion, false);
    }

    return post
        .then(res => {
            return Promise.resolve(res.data);
        })
        .catch(err => {
            console.log({ err });
            return Promise.reject(err);
        });
}


export function startSurvey(surveyId, languageId, participantId) {
    let path = `${servicePath}/start?surveyId=${surveyId}&languageId=${languageId}&participantId=${participantId}`;
    return getWithAuthSurvey(path)
        .then(res => {

            return Promise.resolve(res.data);
        })
        .catch(err => {
            console.error(err);
            return Promise.reject(err);
        });
}

export function submitSurvey(surveyId, languageId, participantId) {
    let path = `${servicePath}/submit?surveyId=${surveyId}&languageId=${languageId}&participantId=${participantId}`;
    return getWithAuthSurvey(path)
        .then(res => {
            return Promise.resolve(res.data);
        })
        .catch(err => {
            console.error(err);
            return Promise.reject(err);
        });
}
